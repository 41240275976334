import { put, takeLatest } from '@redux-saga/core/effects';
import { toast } from 'react-toastify';
import { API } from '../../../utility/axios';
import { coursesActionTypes } from './CoursesActions';

export function* getAllCoursesSaga({ payload }) {
	try {
		const { data } = yield API.get(`course/all`, payload);
		yield put({
			type: coursesActionTypes.GET_COURSES_SUCCESS,
			data: data,
		});
	} catch (e) {
		toast.error(e.response?.data?.message || 'Error in retrieving courses data');
		yield put({ type: coursesActionTypes.GET_COURSES_FAIL });
	}
}

function* watchCoursesSaga() {
	yield takeLatest(coursesActionTypes.GET_COURSES, getAllCoursesSaga);
}

const coursesSagas = [watchCoursesSaga];

export default coursesSagas;
