import { certificateActionTypes } from './CertificateActions';

const initialState = {
	certificateList: [],
	certificateAddLoader: false,
	oneCertificate: null,
	certificateForStudent: null,
};

export default function certificateReducer(state = initialState, action) {
	switch (action.type) {
		case certificateActionTypes.GET_CERTIFICATES_SUCCESS:
			return {
				...state,
				certificateList: action.data,
			};
		case certificateActionTypes.GET_CERTIFICATES_FAIL:
			return {
				...state,
				certificateList: null,
			};
		case certificateActionTypes.SET_ADD_CERTIFICATE_LOADER:
			return {
				...state,
				certificateAddLoader: action.data,
			};
		case certificateActionTypes.GET_CERTIFICATE_BY_CERTIFICATE_ID_SUCCESS:
			return {
				...state,
				oneCertificate: action.data,
			};
		case certificateActionTypes.GET_CERTIFICATE_BY_CERTIFICATE_ID_FAIL:
			return {
				...state,
				oneCertificate: null,
			};
		case certificateActionTypes.SET_ONE_CERTIFICATE:
			return {
				...state,
				oneCertificate: action.data,
			};

		case certificateActionTypes.GET_CERTIFICATES_BY_STUDENT_ID_SUCCESS:
			return {
				...state,
				certificateForStudent: action.data,
			};
		case certificateActionTypes.GET_CERTIFICATES_BY_STUDENT_ID_FAIL:
			return {
				...state,
				certificateForStudent: null,
			};
		default:
			return state;
	}
}
