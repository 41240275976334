import { trainersActionTypes } from './TrainersActions';

const initialState = {
	trainersList: null,
	isTrainerAdding: false,
	isTrainerDeleting: false,
	isTrainerUpdating: false,
	oneTrainer: null,
};

export default function trainersReducer(state = initialState, action) {
	switch (action.type) {
		case trainersActionTypes.GET_TRAINERS_SUCCESS:
			return {
				...state,
				trainersList: action.data,
			};
		case trainersActionTypes.GET_TRAINERS_FAIL:
			return {
				...state,
				trainersList: null,
			};
		case trainersActionTypes.GET_TRAINER_BY_TRAINER_ID_SUCCESS:
			return {
				...state,
				oneTrainer: action.data,
			};
		case trainersActionTypes.GET_TRAINER_BY_TRAINER_ID_FAIL:
			return {
				...state,
				oneTrainer: null,
			};
		case trainersActionTypes.HANDLE_ADD_TRAINER_LOADER:
			return {
				...state,
				isTrainerAdding: action.data,
			};
		case trainersActionTypes.SET_IS_TRAINER_DELETING:
			return {
				...state,
				isTrainerDeleting: action.data,
			};
		case trainersActionTypes.SET_ONE_TRAINER:
			return {
				...state,
				oneTrainer: action.data,
			};
		case trainersActionTypes.SET_IS_TRAINER_UPDATING:
			return {
				...state,
				isTrainerUpdating: action.data,
			};
		default:
			return state;
	}
}
