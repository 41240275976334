export const trainersActionTypes = {
	GET_TRAINERS: 'GET_TRAINERS',
	GET_TRAINERS_SUCCESS: 'GET_TRAINERS_SUCCESS',
	GET_TRAINERS_FAIL: 'GET_TRAINERS_FAIL',
	GET_TRAINER_BY_TRAINER_ID: 'GET_TRAINER_BY_TRAINER_ID',
	GET_TRAINER_BY_TRAINER_ID_SUCCESS: 'GET_TRAINER_BY_TRAINER_ID_SUCCESS',
	GET_TRAINER_BY_TRAINER_ID_FAIL: 'GET_TRAINER_BY_TRAINER_ID_FAIL',
	ADD_TRAINER: 'ADD_TRAINER',
	DELETE_TRAINER: 'DELETE_TRAINER',
	UPDATE_TRAINER: 'UPDATE_TRAINER',
	SET_IS_TRAINER_UPDATING: 'SET_IS_TRAINER_UPDATING',
	HANDLE_ADD_TRAINER_LOADER: 'HANDLE_ADD_TRAINER_LOADER',
	SET_IS_TRAINER_DELETING: 'SET_IS_TRAINER_DELETING',
	SET_ONE_TRAINER: 'SET_ONE_TRAINER',
};

export function getAllTrainers() {
	return { type: trainersActionTypes.GET_TRAINERS };
}

export function getTrainerByTrainerId(trainerId) {
	return { type: trainersActionTypes.GET_TRAINER_BY_TRAINER_ID, trainerId };
}

export function addTrainer(payload, goBack) {
	return { type: trainersActionTypes.ADD_TRAINER, payload, goBack };
}

export function deleteTrainer(trainerId) {
	return { type: trainersActionTypes.DELETE_TRAINER, trainerId };
}

export function updateTrainer(trainerId, payload, goBack) {
	return {
		type: trainersActionTypes.UPDATE_TRAINER,
		trainerId,
		payload,
		goBack,
	};
}

export function setIsUpdatingTrainer(data) {
	return {
		type: trainersActionTypes.SET_IS_TRAINER_UPDATING,
		data,
	};
}

export function setOneTrainer(data) {
	return {
		type: trainersActionTypes.SET_ONE_TRAINER,
		data,
	};
}
