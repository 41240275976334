export const feedbackActionTypes = {
	ADD_FEEDBACK: 'ADD_FEEDBACK',
	GET_ALL_FEEDBACKS: 'GET_ALL_FEEDBACKS',
	GET_ALL_FEEDBACKS_SUCCESS: 'GET_ALL_FEEDBACKS_SUCCESS',
	GET_ALL_FEEDBACKS_FAIL: 'GET_ALL_FEEDBACKS_FAIL',
};

export function addFeedback(data) {
	const { payload } = data;

	return {
		type: feedbackActionTypes.ADD_FEEDBACK,
		payload,
	};
}

export function getAllFeedbacks() {
	return {
		type: feedbackActionTypes.GET_ALL_FEEDBACKS,
	};
}
