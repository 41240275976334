import { coursesActionTypes } from './CoursesActions';
const initialState = {
	coursesList: [],
};

export default function coursesReducer(state = initialState, action) {
	switch (action.type) {
		case coursesActionTypes.GET_COURSES_SUCCESS:
			return {
				...state,
				coursesList: action.data,
			};
		case coursesActionTypes.GET_COURSES_FAIL:
			return {
				...state,
				coursesList: null,
			};
		default:
			return state;
	}
}
