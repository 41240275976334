import * as actionTypes from './constants';
// eslint-disable-next-line no-unused-vars
import { takeLatest, call, put } from 'redux-saga/effects';
import { Auth } from 'aws-amplify';
import { API } from '../../../../utility/axios';
import { fireAlertError } from '../../../../utility/customUtils';
import { USER_ITEM } from '../../../../utility/constants';
import { toast } from 'react-toastify';

/* const loginAsync = async (username, password) => {
	return await Auth.signIn(username, password)
		.then(() => {
			window.localStorage.setItem('user', 'logged');
		})
		.catch((err) => {
			fireAlertCustom('hmmm...', err.message, 'error');
			return false;
		});
}; */

const signoutUserAsync = async () => {
	return await Auth.signOut()
		.then(() => {
			localStorage.remove(USER_ITEM);
			window.location.replace('/coruses');
		})
		.catch((err) => {
			console.error(err.message);
		});
};
///////////////////
//ASYNC FINISHED//
/////////////////

export function* loginUserCB(action) {
	const { data } = action;
	try {
		yield put({
			type: actionTypes.SIGNUP_LOADER_HANDLE,
			payload: true,
		});

		yield API.post(`auth/login`, data).then((res) => {
			window.localStorage.setItem(USER_ITEM, JSON.stringify(res?.data));
			location.replace('/');
			// if (res?.data?.roles === ADMIN) {
			// 	window.localStorage.setItem(USER_ITEM, JSON.stringify(res?.data));
			// 	location.replace('/courses');
			// } else {
			// 	toast.warning('We will be open soon');
			// }
		});
		yield put({
			type: actionTypes.SIGNUP_LOADER_HANDLE,
			payload: false,
		});
		//yield call(loginAsync, data.email, data.password);
		// location.replace('/courses');
	} catch (e) {
		yield put({
			type: actionTypes.SIGNUP_LOADER_HANDLE,
			payload: false,
		});
		yield put({
			type: actionTypes.IS_LOGIN_ERROR,
			data: true,
		});
		fireAlertError('Error', e.response?.data?.message || 'Error in login');
		console.error(e);
	}
}

export function* signoutCB(action) {
	const { history } = action;

	try {
		yield call(signoutUserAsync, history);
	} catch (err) {
		console.error(err.message);
	}
}

export function* changePasswordSaga({ payload }) {
	try {
		yield API.post(`auth/verify-user`, payload);
		yield put({
			type: actionTypes.IS_CHANGE_PASSWORD_ERROR,
			data: false,
		});
		location.replace('/login');
	} catch (e) {
		yield put({
			type: actionTypes.IS_CHANGE_PASSWORD_ERROR,
			data: true,
		});
		fireAlertError('Error', e.response?.data?.message || 'Error in saving new password');
		console.error(e);
	}
}

export function* getUserByUserIdSaga({ userId }) {
	try {
		if (userId) {
			const { data } = yield API.get(`auth/current/user/${userId}`);
			if (data && data.studentId) {
				localStorage.setItem('student-id', data.studentId);
			}
			yield put({
				type: actionTypes.GET_USER_BY_USER_ID_SUCCESS,
				data: data,
			});
			return data;
		}
	} catch (e) {
		yield put({ type: actionTypes.GET_USER_BY_USER_ID_FAIL });
		console.log(e);
	}
}

export function* getVerificationCodeForChangePasswordSaga({ payload, history }) {
	try {
		const data = yield API.patch(`auth/reset-password`, payload);
		/*	yield put({
			type: actionTypes.GET_VERIFICATION_CODE_FOR_CHANGE_PASSWORD_SUCCESS,
			data: data,
		}); */
		yield put({
			type: actionTypes.IS_CHANGE_PASSWORD_ERROR,
			data: false,
		});
		if (data.status === 200) {
			toast.success('Please check your email to get your verification code');
			history();
		} else {
			toast.error('Please enter a valid email');
		}
		//return data;
	} catch (e) {
		yield put({
			type: actionTypes.IS_CHANGE_PASSWORD_ERROR,
			data: true,
		});
		//yield put({ type: actionTypes.GET_VERIFICATION_CODE_FOR_CHANGE_PASSWORD_FAIL });
		console.log(e);
		toast.error(e.response?.data?.message || 'Please Enter the registered email');
	}
}

export function* verifyChangePasswordSaga({ payload, history }) {
	try {
		const data = yield API.patch(`auth/verify-password`, payload);
		yield put({
			type: actionTypes.IS_CHANGE_PASSWORD_ERROR,
			data: false,
		});

		if (data.status === 200) {
			toast.success('Password changed successfully');
			history();
		} else {
			toast.error('There is an error');
		}
	} catch (e) {
		yield put({
			type: actionTypes.IS_CHANGE_PASSWORD_ERROR,
			data: true,
		});
		console.log(e);
		//toast.error('cannot update password');
		toast.error(e.response?.data?.message || 'Cannot update password');
	}
}

// eslint-disable-next-line
export function* testCB() {}

function* watchLoginSagas() {
	yield takeLatest(actionTypes.SIGNUP_SUCCESS, testCB);
	yield takeLatest(actionTypes.LOGIN_LISTEN, loginUserCB);
	yield takeLatest(actionTypes.SIGNUP_OUT, signoutCB);
	yield takeLatest(actionTypes.CHANGE_PASSWORD, changePasswordSaga);
	yield takeLatest(actionTypes.GET_USER_BY_USER_ID, getUserByUserIdSaga);
	yield takeLatest(actionTypes.GET_VERIFICATION_CODE_FOR_CHANGE_PASSWORD, getVerificationCodeForChangePasswordSaga);
	yield takeLatest(actionTypes.VERIFY_CHANGE_PASSWORD, verifyChangePasswordSaga);
}

const loginSagas = [watchLoginSagas];

export default loginSagas;
