import { learningMaterialsActionTypes } from './LearningMaterialsActions';
const initialState = {
	isDeletingLoader: false,
	oneLearningMaterial: null,
	isLearningMaterialUpdating: false,
};

export default function learningMaterialsReducer(state = initialState, action) {
	switch (action.type) {
		case learningMaterialsActionTypes.SET_IS_LEARNING_MATERIAL_DELETING:
			return {
				...state,
				isDeletingLoader: action.data,
			};

		case learningMaterialsActionTypes.GET_LEARNING_MATERIAL_BY_COURSE_ID_SUCCESS:
			return {
				...state,
				oneLearningMaterial: action.data,
			};
		case learningMaterialsActionTypes.GET_LEARNING_MATERIAL_BY_COURSE_ID_FAIL:
			return {
				...state,
				oneLearningMaterial: null,
			};
		case learningMaterialsActionTypes.SET_IS_LEARNING_MATERIAL_UPDATING:
			return {
				...state,
				isLearningMaterialUpdating: action.data,
			};
		case learningMaterialsActionTypes.SET_ONE_LEARNING_MATERIAL:
			return {
				...state,
				oneLearningMaterial: action.data,
			};
		default:
			return state;
	}
}
