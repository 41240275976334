import { put, takeLatest } from '@redux-saga/core/effects';
import { toast } from 'react-toastify';
import { API } from '../../../utility/axios';
import { trainingMaterialsActionTypes } from './TrainingMaterialsActions';

export function* getOneTrainingMaterialSaga({ id }) {
	try {
		const { data } = yield API.get(`training-materials/material/${id}`);
		yield put({
			type: trainingMaterialsActionTypes.GET_ONE_TRAINING_MATERIAL_SUCCESS,
			data: data,
		});
	} catch (e) {
		toast.error(e.response?.data?.message || 'Error in retrieving training material');
		yield put({ type: trainingMaterialsActionTypes.GET_ONE_TRAINING_MATERIAL_FAIL });
		console.log(e);
	}
}

export function* getTrainingMaterialsByBatchIdSaga({ batchId }) {
	try {
		const { data } = yield API.get(`training-materials/batch/material/${batchId}`);
		yield put({
			type: trainingMaterialsActionTypes.GET_TRAINING_MATERIALS_BY_BATCH_ID_SUCCESS,
			data: data,
		});
	} catch (e) {
		// toast.error(e.response?.data?.message || 'Error in retrieving training materials');
		yield put({ type: trainingMaterialsActionTypes.GET_TRAINING_MATERIALS_BY_BATCH_ID_FAIL });
		console.log(e);
	}
}

export function* addTrainingMaterialSaga({ payload, batchId }) {
	try {
		yield API.post(`training-materials/create`, payload);
		yield put({
			type: trainingMaterialsActionTypes.SET_IS_UPLOADING,
			data: false,
		});
		yield getTrainingMaterialsByBatchIdSaga({
			batchId,
		});
		toast.success('Training material added successfully');
	} catch (e) {
		toast.error(e.response?.data?.message || 'Error in adding training material');
		yield put({
			type: trainingMaterialsActionTypes.SET_IS_ERROR,
			data: true,
		});
		console.log(e);
	}
}

export function* addDocumentToExistingTrainingMaterialSaga({ payload, batchId }) {
	try {
		yield API.patch(`training-materials/update-materials/documents`, payload);
		yield put({
			type: trainingMaterialsActionTypes.SET_IS_UPLOADING,
			data: false,
		});
		yield getTrainingMaterialsByBatchIdSaga({
			batchId,
		});
		toast.success('Training material document added successfully');
	} catch (e) {
		toast.error(e.response?.data?.message || 'Error in adding training material document');
		yield put({
			type: trainingMaterialsActionTypes.SET_IS_ERROR,
			data: true,
		});
		console.log(e);
	}
}

export function* updateTrainingMaterialNameSaga({ trainingMaterialsId, payload }) {
	try {
		yield API.patch(`training-materials/materials/${trainingMaterialsId}`, payload);
		toast.success('Training material name updated');
	} catch (e) {
		toast.error(e.response?.data?.message || 'Error in updating training material name');
		console.log(e);
	}
}

export function* deleteTrainingMaterialSaga({ trainingMaterialId }) {
	try {
		yield API.delete(`training-materials/remove/training-material/${trainingMaterialId}`);
		// set loader to false
		yield put({
			type: trainingMaterialsActionTypes.SET_IS_TRAINING_MATERIAL_DELETING,
			data: false,
		});
		// set deletion success to true
		yield put({
			type: trainingMaterialsActionTypes.SET_IS_TRAINING_MATERIAL_DELETED,
			data: true,
		});
		toast.success('Training material deleted successfully');
	} catch (e) {
		toast.error(e.response?.data?.message || 'Error in deleting training material');
		// set loader to false
		yield put({
			type: trainingMaterialsActionTypes.SET_IS_TRAINING_MATERIAL_DELETING,
			data: false,
		});
		// set deletion error to true
		yield put({
			type: trainingMaterialsActionTypes.SET_IS_ERROR_IN_TRAINING_MATERIAL_DELETE,
			data: true,
		});
		console.log(e);
	}
}

export function* deleteTrainingMaterialDocumentSaga({ docId }) {
	try {
		yield API.delete(`training-materials/remove/document/${docId}`);
		yield put({
			type: trainingMaterialsActionTypes.SET_IS_TRAINING_MATERIAL_DOCUMENT_DELETED,
			data: true,
		});
		toast.success('Training material document deleted successfully');
	} catch (e) {
		toast.error(e.response?.data?.message || 'Error in deleting training material document');
		yield put({
			type: trainingMaterialsActionTypes.SET_IS_ERROR,
			data: true,
		});
		console.log(e);
	}
}

function* watchTrainingMaterialsSaga() {
	yield takeLatest(trainingMaterialsActionTypes.GET_ONE_TRAINING_MATERIAL, getOneTrainingMaterialSaga);
	yield takeLatest(
		trainingMaterialsActionTypes.GET_TRAINING_MATERIALS_BY_BATCH_ID,
		getTrainingMaterialsByBatchIdSaga,
	);
	yield takeLatest(trainingMaterialsActionTypes.ADD_TRAINING_MATERIAL, addTrainingMaterialSaga);
	yield takeLatest(trainingMaterialsActionTypes.UPDATE_TRAINING_MATERIAL_NAME, updateTrainingMaterialNameSaga);
	yield takeLatest(
		trainingMaterialsActionTypes.ADD_DOCUMENT_TO_EXISTING_TRAINING_MATERIAL,
		addDocumentToExistingTrainingMaterialSaga,
	);
	yield takeLatest(trainingMaterialsActionTypes.DELETE_TRAINING_MATERIAL, deleteTrainingMaterialSaga);
	yield takeLatest(
		trainingMaterialsActionTypes.DELETE_TRAINING_MATERIAL_DOCUMENT,
		deleteTrainingMaterialDocumentSaga,
	);
}

const trainingMaterialsSagas = [watchTrainingMaterialsSaga];

export default trainingMaterialsSagas;
