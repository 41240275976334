import { toast } from 'react-toastify';
import { API } from '../../../utility/axios';
import { takeLatest, put } from '@redux-saga/core/effects';
import { feedbackActionTypes } from './FeedbackActions';

export function* addFeedbackSaga({ payload }) {
	try {
		const response = yield API.post(`feedback/create`, payload);

		if (response.status === 201) {
			toast.success('Feedback added successfully');
		}
	} catch (e) {
		let message = 'Cannot add Feedback';
		if (e?.response?.data?.message) {
			message = `${message}: ${e.response.data.message}`;
		} else if (e?.response?.data?.error) {
			message = `${message}: ${e.response.data.error}`;
		}
		toast.error(message);
		console.error(e);
	}
}

export function* getAllFeedbacksSaga() {
	try {
		const { data } = yield API.get(`feedback/all`);
		yield put({
			type: feedbackActionTypes.GET_ALL_FEEDBACKS_SUCCESS,
			data: data,
		});
	} catch (e) {
		toast.error(e.response?.data?.message || 'Error in retrieving feedback data');
		yield put({ type: feedbackActionTypes.GET_ALL_FEEDBACKS_FAIL });
	}
}

function* watchFeedbackSaga() {
	yield takeLatest(feedbackActionTypes.ADD_FEEDBACK, addFeedbackSaga);
	yield takeLatest(feedbackActionTypes.GET_ALL_FEEDBACKS, getAllFeedbacksSaga);
}

const feedbackSagas = [watchFeedbackSaga];

export default feedbackSagas;
