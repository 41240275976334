import * as actionTypes from './constants';

const init = {
	isError: false,
	isChangePasswordError: false,
	isSignInLoader: false,
	user: null,
	verificationCode: null,
};

const loginReducer = (state = init, action) => {
	switch (action.type) {
		case actionTypes.IS_LOGIN_ERROR:
			return {
				...state,
				isError: action.data,
			};
		case actionTypes.SIGNUP_LOADER_HANDLE:
			return {
				...state,
				isSignInLoader: action.payload,
			};
		case actionTypes.IS_CHANGE_PASSWORD_ERROR:
			return {
				...state,
				isChangePasswordError: action.data,
			};
		case actionTypes.GET_USER_BY_USER_ID_SUCCESS:
			return {
				...state,
				user: action.data,
			};
		case actionTypes.GET_USER_BY_USER_ID_FAIL:
			return {
				...state,
				user: null,
			};
		case actionTypes.GET_VERIFICATION_CODE_FOR_CHANGE_PASSWORD_SUCCESS:
			return {
				...state,
				verificationCode: action.data,
			};
		case actionTypes.GET_VERIFICATION_CODE_FOR_CHANGE_PASSWORD_FAIL:
			return {
				...state,
				verificationCode: null,
			};
		default:
			return state;
	}
};

export default loginReducer;
