import { batchesActionTypes } from './BatchesActions';

const initialState = {
	batchesList: null,
	isBatchAddLoader: false,
	oneBatch: null,
	isBatchUpdating: false,
	isDeletingLoader: false,
};

export default function batchesReducer(state = initialState, action) {
	switch (action.type) {
		case batchesActionTypes.GET_BATCH_SUCCESS:
			return {
				...state,
				batchesList: action.data,
			};
		case batchesActionTypes.GET_BATCH_FAIL:
			return {
				...state,
				batchesList: null,
			};
		case batchesActionTypes.HANDLE_ADD_BATCH_LOADER:
			return {
				...state,
				isBatchAddLoader: action.payload,
			};
		case batchesActionTypes.GET_BATCH_BY_BATCH_ID_SUCCESS:
			return {
				...state,
				oneBatch: action.data,
			};
		case batchesActionTypes.GET_BATCH_BY_BATCH_ID_FAIL:
			return {
				...state,
				oneBatch: null,
			};
		case batchesActionTypes.SET_IS_BATCH_UPDATING:
			return {
				...state,
				isBatchUpdating: action.data,
			};
		case batchesActionTypes.SET_IS_BATCH_DELETING:
			return {
				...state,
				isDeletingLoader: action.data,
			};
		case batchesActionTypes.SET_ONE_BATCH:
			return {
				...state,
				oneBatch: action.data,
			};
		default:
			return state;
	}
}
