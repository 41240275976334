export const certificateActionTypes = {
	GET_CERTIFICATES: 'GET_CERTIFICATES',
	GET_CERTIFICATES_SUCCESS: 'GET_CERTIFICATES_SUCCESS',
	GET_CERTIFICATES_FAIL: 'GET_CERTIFICATES_FAIL',
	ADD_CERTIFICATE: 'ADD_CERTIFICATE',
	SET_ADD_CERTIFICATE_LOADER: 'SET_ADD_CERTIFICATE_LOADER',
	GET_CERTIFICATE_BY_CERTIFICATE_ID: 'GET_CERTIFICATE_BY_CERTIFICATE_ID',
	GET_CERTIFICATE_BY_CERTIFICATE_ID_SUCCESS: 'GET_CERTIFICATE_BY_CERTIFICATE_ID_SUCCESS',
	GET_CERTIFICATE_BY_CERTIFICATE_ID_FAIL: 'GET_CERTIFICATE_BY_CERTIFICATE_ID_FAIL',
	SET_ONE_CERTIFICATE: 'SET_ONE_CERTIFICATE',
	GET_CERTIFICATE_BY_STUDENT_ID: 'GET_CERTIFICATE_BY_STUDENT_ID',
	GET_CERTIFICATES_BY_STUDENT_ID_SUCCESS: 'GET_CERTIFICATES_BY_STUDENT_ID_SUCCESS',
	GET_CERTIFICATES_BY_STUDENT_ID_FAIL: 'GET_CERTIFICATES_BY_STUDENT_ID_FAIL',
};

export function getAllCertificates(payload) {
	return { type: certificateActionTypes.GET_CERTIFICATES, payload };
}

export function addCertificate(data) {
	const { payload, history } = data;

	return {
		type: certificateActionTypes.ADD_CERTIFICATE,
		payload,
		history,
	};
}

export function setAddCertificateLoader(data) {
	return {
		type: certificateActionTypes.SET_ADD_CERTIFICATE_LOADER,
		data,
	};
}

export function getCertificateByCertificateId(certificateId) {
	return { type: certificateActionTypes.GET_CERTIFICATE_BY_CERTIFICATE_ID, certificateId };
}

export function setOneCertificate(data) {
	return {
		type: certificateActionTypes.SET_ONE_CERTIFICATE,
		data,
	};
}

export function getCertificateByStudentId(studentId) {
	return { type: certificateActionTypes.GET_CERTIFICATE_BY_STUDENT_ID, studentId };
}
