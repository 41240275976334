import { feedbackActionTypes } from './FeedbackActions';

const initialState = {
	feedbackList: null,
};

export default function feedbackReducer(state = initialState, action) {
	switch (action.type) {
		case feedbackActionTypes.GET_ALL_FEEDBACKS_SUCCESS:
			return {
				...state,
				feedbackList: action.data,
			};
		case feedbackActionTypes.GET_ALL_FEEDBACKS_FAIL:
			return {
				...state,
				feedbackList: [],
			};
		default:
			return state;
	}
}
