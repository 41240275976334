import { put, takeLatest } from '@redux-saga/core/effects';
import { getAllStudents, handleAddStudentLoader, studentsActionTypes } from './StudentsActions';
import { API } from '../../../utility/axios';
import { toast } from 'react-toastify';
import { jsonToFormData } from '../../../utility/customUtils';

export function* getAllStudentsSaga({ payload }) {
	try {
		const { data } = yield API.get(`student/all`, payload);
		yield put({
			type: studentsActionTypes.GET_STUDENTS_SUCCESS,
			data: data,
		});
	} catch (e) {
		toast.error(e.response?.data?.message || 'Error in retrieving students data');
		yield put({ type: studentsActionTypes.GET_STUDENTS_FAIL });
	}
}

export function* getStudentByUserIdSaga({ userId }) {
	try {
		const { data } = yield API.get(`student/user/${userId}`);
		yield put({
			type: studentsActionTypes.GET_STUDENT_BY_USER_ID_SUCCESS,
			data: data,
		});
	} catch (e) {
		toast.error(e.response?.data?.message || 'Error in retrieving student data');
		yield put({ type: studentsActionTypes.GET_STUDENT_BY_USER_ID_FAIL });
	}
}

export function* addStudentSaga({ payload, history }) {
	try {
		yield put(handleAddStudentLoader(true));
		const response = yield API.post(`student/create`, jsonToFormData(payload), {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});

		if (response.status === 201) {
			toast.success('User added successfully');
			history();
		}
		yield put(handleAddStudentLoader(false));
	} catch (e) {
		yield put(handleAddStudentLoader(false));
		let message = 'Cannot add user';
		if (e?.response?.data?.message) {
			message = `${message}: ${e.response.data.message}`;
		} else if (e?.response?.data?.error) {
			message = `${message}: ${e.response.data.error}`;
		}
		toast.error(message);
		console.error(e);
	}
}

export function* updateStudentSaga({ studentId, payload, goBack }) {
	try {
		yield put({
			type: studentsActionTypes.SET_IS_STUDENT_UPDATING,
			data: true,
		});
		const response = yield API.put(`student/update/${studentId}`, jsonToFormData(payload), {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
		if (response.status === 200) {
			yield put({
				type: studentsActionTypes.SET_IS_STUDENT_UPDATING,
				data: false,
			});
			toast.success('User updated successfully');
			goBack();
		}
	} catch (e) {
		yield put({
			type: studentsActionTypes.SET_IS_STUDENT_UPDATING,
			data: false,
		});
		toast.error(e.response?.data?.message || 'Error in updating user');
	}
}

export function* deleteStudentSaga({ studentId }) {
	try {
		yield put({
			type: studentsActionTypes.SET_IS_STUDENT_DELETING,
			data: true,
		});
		const response = yield API.delete(`student/remove/${studentId}`);
		if (response.status === 200) {
			yield put(getAllStudents());
			yield put({
				type: studentsActionTypes.SET_IS_STUDENT_DELETING,
				data: false,
			});
			toast.success('User deleted successfully');
		}
	} catch (e) {
		yield put({
			type: studentsActionTypes.SET_IS_STUDENT_DELETING,
			data: false,
		});
		toast.error(e.response?.data?.message || 'Error in deleting user');
	}
}

export function* exportStudentSaga({ payload, fileName }) {
	try {
		let file = null;
		if (payload.exportType === 'AS_COURSE') {
			const { data } = yield API.get(
				`student/export?courseId=${payload.courseId}&exportType=${payload.exportType}`,
				{
					headers: { 'Content-Type': 'blob' },
					responseType: 'blob',
				},
			);
			if (data) {
				file = data;
			}
		} else if (payload.exportType === 'AS_BATCH') {
			const { data } = yield API.get(
				`student/export?batchId=${payload.batchId}&exportType=${payload.exportType}`,
				{
					headers: { 'Content-Type': 'blob' },
					responseType: 'blob',
				},
			);
			if (data) {
				file = data;
			}
		} else {
			const { data } = yield API.get(`student/export?exportType=${payload.exportType}`, {
				headers: { 'Content-Type': 'blob' },
				responseType: 'blob',
			});
			if (data) {
				file = data;
			}
		}

		// download file
		if (file) {
			const url = window.URL.createObjectURL(new Blob([file]));
			const link = document.createElement('a');
			link.download = `${fileName}.xlsx`;
			link.href = url;
			link.click();
		}
	} catch (e) {
		toast.error(e.response?.data?.message || 'Error in export students data');
		yield put({ type: studentsActionTypes.EXPORT_STUDENT_FAIL });
	}
}

export function* changeUserStatusSaga({ userId }) {
	try {
		yield put({
			type: studentsActionTypes.CHANGE_USER_STATUS_LOADER,
			data: true,
		});
		const response = yield API.patch(`auth/change-user-status/${userId}`);
		if (response.status === 200) {
			yield put(getAllStudents());
			yield put({
				type: studentsActionTypes.CHANGE_USER_STATUS_LOADER,
				data: false,
			});
			toast.success('User status changed successfully');
		}
	} catch (e) {
		yield put({
			type: studentsActionTypes.CHANGE_USER_STATUS_LOADER,
			data: false,
		});
		toast.error(e.response?.data?.message || 'Error in changing user status');
	}
}

function* watchStudentsSaga() {
	yield takeLatest(studentsActionTypes.GET_STUDENTS, getAllStudentsSaga);
	yield takeLatest(studentsActionTypes.GET_STUDENT_BY_USER_ID, getStudentByUserIdSaga);
	yield takeLatest(studentsActionTypes.ADD_STUDENT, addStudentSaga);
	yield takeLatest(studentsActionTypes.UPDATE_STUDENT, updateStudentSaga);
	yield takeLatest(studentsActionTypes.DELETE_STUDENT, deleteStudentSaga);
	yield takeLatest(studentsActionTypes.EXPORT_STUDENT, exportStudentSaga);
	yield takeLatest(studentsActionTypes.CHANGE_USER_STATUS, changeUserStatusSaga);
}

const studentsSagas = [watchStudentsSaga];

export default studentsSagas;
