import { notificationsActionTypes } from './NotificationsActions';
const initialState = {
	notificationsByStudentId: null,
	notificationsList: null,
	oneNotification: null,
	notificationUpdateLoader: false,
	notificationAddLoader: false,
	notificationDeleteLoader: false,
};

export default function notificationsReducer(state = initialState, action) {
	switch (action.type) {
		case notificationsActionTypes.GET_NOTIFICATIONS_BY_STUDENT_ID_SUCCESS:
			return {
				...state,
				notificationsByStudentId: action.data,
			};
		case notificationsActionTypes.GET_NOTIFICATIONS_BY_STUDENT_ID_FAIL:
			return {
				...state,
				notificationsByStudentId: null,
			};

		case notificationsActionTypes.GET_NOTIFICATIONS_SUCCESS:
			return {
				...state,
				notificationsList: action.data,
			};
		case notificationsActionTypes.GET_NOTIFICATIONS_FAIL:
			return {
				...state,
				notificationsList: [],
			};

		case notificationsActionTypes.GET_NOTIFICATION_BY_NOTIFICATION_ID_SUCCESS:
			return {
				...state,
				oneNotification: action.data,
			};
		case notificationsActionTypes.GET_NOTIFICATION_BY_NOTIFICATION_ID_FAIL:
			return {
				...state,
				oneNotification: null,
			};

		case notificationsActionTypes.SET_IS_NOTIFICATION_UPDATING:
			return {
				...state,
				notificationUpdateLoader: action.data,
			};
		case notificationsActionTypes.SET_IS_NOTIFICATION_ADDING:
			return {
				...state,
				notificationAddLoader: action.data,
			};
		case notificationsActionTypes.SET_IS_NOTIFICATION_DELETING:
			return {
				...state,
				notificationDeleteLoader: action.data,
			};

		case notificationsActionTypes.SET_ONE_NOTIFICATION:
			return {
				...state,
				oneNotification: action.data,
			};

		default:
			return state;
	}
}
