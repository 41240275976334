export const LOGIN_LISTEN = 'login/LOGIN_LISTEN';
export const LOGIN_SUCCESS = 'login/LOGIN_SUCCESS';

export const SIGNUP_OUT = 'Login/SIGNUP_OUT';
export const SIGNUP_SUCCESS = 'Login/SIGNUP_SUCCESS';
export const SIGNUP_LOADER_HANDLE = 'Login/SIGNUP_LOADER_HANDLE';

export const CHANGE_PASSWORD = 'login/CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'login/CHANGE_PASSWORD_SUCCESS';

export const IS_LOGIN_ERROR = 'login/IS_LOGIN_ERROR';
export const IS_CHANGE_PASSWORD_ERROR = 'login/IS_CHANGE_PASSWORD_ERROR';

export const GET_USER_BY_USER_ID = 'login/GET_USER_BY_USER_ID';
export const GET_USER_BY_USER_ID_SUCCESS = 'login/GET_USER_BY_USER_ID_SUCCESS';
export const GET_USER_BY_USER_ID_FAIL = 'login/GET_USER_BY_USER_ID_FAIL';

export const GET_VERIFICATION_CODE_FOR_CHANGE_PASSWORD = 'login/GET_VERIFICATION_CODE_CODE_FOR_CHANGE_PASSWORD';
export const GET_VERIFICATION_CODE_FOR_CHANGE_PASSWORD_SUCCESS =
	'login/GET_VERIFICATION_CODE_FOR_CHANGE_PASSWORD_SUCCESS';
export const GET_VERIFICATION_CODE_FOR_CHANGE_PASSWORD_FAIL = 'login/GET_VERIFICATION_CODE_FOR_CHANGE_PASSWORD_FAIL';

export const VERIFY_CHANGE_PASSWORD = 'login/VERIFY_CHANGE_PASSWORD';
export const VERIFY_CHANGE_PASSWORD_SUCCESS = 'login/VERIFY_CHANGE_PASSWORD_SUCCESS';
