import { toast } from 'react-toastify';
import { API } from '../../../utility/axios';
import { batchesActionTypes, getAllBatches, handleAddBatchLoader } from './BatchesActions';
import { put, takeLatest } from '@redux-saga/core/effects';

export function* getAllBatchesSaga() {
	try {
		const { data } = yield API.get(`batch/all`);
		yield put({
			type: batchesActionTypes.GET_BATCH_SUCCESS,
			data: data,
		});
	} catch (e) {
		toast.error(e.response?.data?.message || 'Error in retrieving batch data');
		yield put({ type: batchesActionTypes.GET_BATCH_FAIL });
	}
}

export function* addBatchSaga({ payload, history }) {
	try {
		yield put(handleAddBatchLoader(true));
		const response = yield API.post(`batch/create`, payload);

		if (response.status === 201) {
			toast.success('Batch added successfully');
			history();
		}
		yield put(handleAddBatchLoader(false));
	} catch (e) {
		yield put(handleAddBatchLoader(false));
		let message = 'Cannot add batch';
		if (e?.response?.data?.message) {
			message = `${message}: ${e.response.data.message}`;
		} else if (e?.response?.data?.error) {
			message = `${message}: ${e.response.data.error}`;
		}
		toast.error(message);
		console.error(e);
	}
}

export function* getBatchByBatchIdSaga({ batchId }) {
	try {
		const { data } = yield API.get(`batch/find/${batchId}`);
		yield put({
			type: batchesActionTypes.GET_BATCH_BY_BATCH_ID_SUCCESS,
			data: data,
		});
	} catch (e) {
		toast.error(e.response?.data?.message || 'Error in retrieving batch data');
		yield put({ type: batchesActionTypes.GET_BATCH_BY_BATCH_ID_FAIL });
	}
}

export function* updateBatchSaga({ batchId, payload, goBack }) {
	try {
		yield put({
			type: batchesActionTypes.SET_IS_BATCH_UPDATING,
			data: true,
		});
		const response = yield API.put(`batch/update/${batchId}`, payload);
		if (response.status === 200) {
			yield put({
				type: batchesActionTypes.SET_IS_BATCH_UPDATING,
				data: false,
			});
			toast.success('Batch updated successfully');
			goBack();
		}
	} catch (e) {
		yield put({
			type: batchesActionTypes.SET_IS_BATCH_UPDATING,
			data: false,
		});
		toast.error(e.response?.data?.message || 'Error in updating batch');
	}
}

export function* deleteBatchSaga({ batchId }) {
	try {
		yield put({
			type: batchesActionTypes.SET_IS_BATCH_DELETING,
			data: true,
		});
		const response = yield API.delete(`batch/remove/${batchId}`);
		if (response.status === 200) {
			yield put(getAllBatches());
			yield put({
				type: batchesActionTypes.SET_IS_BATCH_DELETING,
				data: false,
			});
			toast.success('Batch deleted successfully');
		}
	} catch (e) {
		yield put({
			type: batchesActionTypes.SET_IS_BATCH_DELETING,
			data: false,
		});
		toast.error(e.response?.data?.message || 'Error in deleting Batch');
	}
}

function* watchBatchesSaga() {
	yield takeLatest(batchesActionTypes.GET_ALL_BATCHES, getAllBatchesSaga);
	yield takeLatest(batchesActionTypes.ADD_BATCH, addBatchSaga);
	yield takeLatest(batchesActionTypes.GET_BATCH_BY_BATCH_ID, getBatchByBatchIdSaga);
	yield takeLatest(batchesActionTypes.UPDATE_BATCH, updateBatchSaga);
	yield takeLatest(batchesActionTypes.DELETE_BATCH, deleteBatchSaga);
}

const batchesSagas = [watchBatchesSaga];

export default batchesSagas;
