export const trainingMaterialsActionTypes = {
	GET_ONE_TRAINING_MATERIAL: 'GET_ONE_TRAINING_MATERIAL',
	GET_ONE_TRAINING_MATERIAL_SUCCESS: 'GET_ONE_TRAINING_MATERIAL_SUCCESS',
	GET_ONE_TRAINING_MATERIAL_FAIL: 'GET_ONE_TRAINING_MATERIAL_FAIL',
	GET_TRAINING_MATERIALS_BY_BATCH_ID: 'GET_TRAINING_MATERIALS_BY_BATCH_ID',
	GET_TRAINING_MATERIALS_BY_BATCH_ID_SUCCESS: 'GET_TRAINING_MATERIALS_BY_BATCH_ID_SUCCESS',
	GET_TRAINING_MATERIALS_BY_BATCH_ID_FAIL: 'GET_TRAINING_MATERIALS_BY_BATCH_ID_FAIL',
	ADD_TRAINING_MATERIAL: 'ADD_TRAINING_MATERIAL',
	ADD_DOCUMENT_TO_EXISTING_TRAINING_MATERIAL: 'ADD_DOCUMENT_TO_EXISTING_TRAINING_MATERIAL',
	UPDATE_TRAINING_MATERIAL_NAME: 'UPDATE_TRAINING_MATERIAL_NAME',
	DELETE_TRAINING_MATERIAL: 'DELETE_TRAINING_MATERIAL',
	DELETE_TRAINING_MATERIAL_DOCUMENT: 'DELETE_TRAINING_MATERIAL_DOCUMENT',
	SET_IS_UPLOADING: 'SET_IS_UPLOADING',
	SET_IS_TRAINING_MATERIAL_DELETING: 'SET_IS_TRAINING_MATERIAL_DELETING',
	SET_IS_TRAINING_MATERIAL_DELETED: 'SET_IS_TRAINING_MATERIAL_DELETED',
	SET_IS_TRAINING_MATERIAL_DOCUMENT_DELETED: 'SET_IS_TRAINING_MATERIAL_DOCUMENT_DELETED',
	SET_UPLOADED_FILES: 'SET_UPLOADED_FILES',
	SET_IS_ERROR: 'SET_IS_ERROR',
	SET_IS_ERROR_IN_TRAINING_MATERIAL_DELETE: 'SET_IS_ERROR_IN_TRAINING_MATERIAL_DELETE',
	SET_TRAINING_MATERIALS: 'SET_TRAINING_MATERIALS',
	SET_TRAINING_MATERIALS_NOT_FOUND: 'SET_TRAINING_MATERIALS_NOT_FOUND',
};

export function getOneTrainingMaterial(id) {
	return { type: trainingMaterialsActionTypes.GET_ONE_TRAINING_MATERIAL, id };
}

export function getTrainingMaterialSByBatchId(batchId) {
	return { type: trainingMaterialsActionTypes.GET_TRAINING_MATERIALS_BY_BATCH_ID, batchId };
}

export function setTrainingMaterialSByBatchId(data) {
	return { type: trainingMaterialsActionTypes.GET_TRAINING_MATERIALS_BY_BATCH_ID_SUCCESS, data };
}

export function addTrainingMaterial(payload, batchId) {
	return { type: trainingMaterialsActionTypes.ADD_TRAINING_MATERIAL, payload, batchId };
}

export function addDocumentsToExistingTrainingMaterial(payload, batchId) {
	return { type: trainingMaterialsActionTypes.ADD_DOCUMENT_TO_EXISTING_TRAINING_MATERIAL, payload, batchId };
}

export function updateTrainingMaterialName(trainingMaterialsId, payload) {
	return { type: trainingMaterialsActionTypes.UPDATE_TRAINING_MATERIAL_NAME, trainingMaterialsId, payload };
}

export function deleteTrainingMaterial(trainingMaterialId) {
	return { type: trainingMaterialsActionTypes.DELETE_TRAINING_MATERIAL, trainingMaterialId };
}

export function deleteTrainingMaterialDocument(docId) {
	return { type: trainingMaterialsActionTypes.DELETE_TRAINING_MATERIAL_DOCUMENT, docId };
}

export function setIsUploading(data) {
	return { type: trainingMaterialsActionTypes.SET_IS_UPLOADING, data };
}

export function setIsTrainingMaterialDeleting(data) {
	return { type: trainingMaterialsActionTypes.SET_IS_TRAINING_MATERIAL_DELETING, data };
}

export function setIsTrainingMaterialDeleted(data) {
	return { type: trainingMaterialsActionTypes.SET_IS_TRAINING_MATERIAL_DELETED, data };
}

export function setIsTrainingMaterialDocumentDeleted(data) {
	return { type: trainingMaterialsActionTypes.SET_IS_TRAINING_MATERIAL_DOCUMENT_DELETED, data };
}

export function setUploadedFiles(data) {
	return { type: trainingMaterialsActionTypes.SET_UPLOADED_FILES, data };
}

export function setIsError(data) {
	return { type: trainingMaterialsActionTypes.SET_IS_ERROR, data };
}

export function setIsErrorInTrainingMaterialDelete(data) {
	return { type: trainingMaterialsActionTypes.SET_IS_ERROR_IN_TRAINING_MATERIAL_DELETE, data };
}

export function setTrainingMaterials(data) {
	return { type: trainingMaterialsActionTypes.SET_TRAINING_MATERIALS, data };
}

export function setTrainingMaterialsNotFound(data) {
	return { type: trainingMaterialsActionTypes.SET_TRAINING_MATERIALS_NOT_FOUND, data };
}
