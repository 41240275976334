export const batchesActionTypes = {
	GET_ALL_BATCHES: 'GET_ALL_BATCHES',
	GET_BATCH_SUCCESS: 'GET_BATCH_SUCCESS',
	GET_BATCH_FAIL: 'GET_BATCH_FAIL',
	ADD_BATCH: 'ADD_BATCH',
	HANDLE_ADD_BATCH_LOADER: 'HANDLE_ADD_BATCH_LOADER',
	GET_BATCH_BY_BATCH_ID: 'GET_BATCH_BY_BATCH_ID',
	GET_BATCH_BY_BATCH_ID_SUCCESS: 'GET_BATCH_BY_BATCH_ID_SUCCESS',
	GET_BATCH_BY_BATCH_ID_FAIL: 'GET_BATCH_BY_BATCH_ID_FAIL',
	UPDATE_BATCH: 'UPDATE_BATCH',
	SET_IS_BATCH_UPDATING: 'SET_IS_BATCH_UPDATING',
	DELETE_BATCH: 'DELETE_BATCH',
	SET_IS_BATCH_DELETING: 'SET_IS_BATCH_DELETING',
	SET_ONE_BATCH: 'SET_ONE_BATCH',
};

export function getAllBatches() {
	return { type: batchesActionTypes.GET_ALL_BATCHES };
}

export function addBatch(data) {
	const { payload, history } = data;

	return {
		type: batchesActionTypes.ADD_BATCH,
		payload,
		history,
	};
}

export function handleAddBatchLoader(payload) {
	return {
		type: batchesActionTypes.HANDLE_ADD_BATCH_LOADER,
		payload,
	};
}

export function getBatchByBatchId(batchId) {
	return { type: batchesActionTypes.GET_BATCH_BY_BATCH_ID, batchId };
}

export function updateBatch(batchId, payload, goBack) {
	return {
		type: batchesActionTypes.UPDATE_BATCH,
		batchId,
		payload,
		goBack,
	};
}

export function setIsUpdatingBatch(data) {
	return {
		type: batchesActionTypes.SET_IS_BATCH_UPDATING,
		data,
	};
}

export function deleteBatch(batchId) {
	return {
		type: batchesActionTypes.DELETE_BATCH,
		batchId,
	};
}

export function deleteBatchLoader(data) {
	return {
		type: batchesActionTypes.SET_IS_BATCH_DELETING,
		data,
	};
}

export function setOneBatch(data) {
	return {
		type: batchesActionTypes.SET_ONE_BATCH,
		data,
	};
}
