import { put, takeLatest } from '@redux-saga/core/effects';
import { trainersActionTypes } from './TrainersActions';
import { API } from '../../../utility/axios';
import { toast } from 'react-toastify';
import { jsonToFormData } from '../../../utility/customUtils';

export function* getAllTrainersSaga() {
	try {
		const { data } = yield API.get(`trainers/all`);
		yield put({
			type: trainersActionTypes.GET_TRAINERS_SUCCESS,
			data: data,
		});
	} catch (e) {
		toast.error(e.response?.data?.message || 'Error in retrieving trainers data');
		yield put({ type: trainersActionTypes.GET_TRAINERS_FAIL });
	}
}

export function* getTrainerByTrainerIdSaga({ trainerId }) {
	try {
		const { data } = yield API.get(`trainers/find/${trainerId}`);
		yield put({
			type: trainersActionTypes.GET_TRAINER_BY_TRAINER_ID_SUCCESS,
			data: data,
		});
	} catch (e) {
		toast.error(e.response?.data?.message || 'Error in retrieving trainer data');
		yield put({ type: trainersActionTypes.GET_TRAINER_BY_TRAINER_ID_FAIL });
	}
}

export function* addTrainerSaga({ payload, goBack }) {
	try {
		yield put({
			type: trainersActionTypes.HANDLE_ADD_TRAINER_LOADER,
			data: true,
		});
		const response = yield API.post(`trainers/create`, jsonToFormData(payload));
		if (response.status === 201) {
			yield put({
				type: trainersActionTypes.HANDLE_ADD_TRAINER_LOADER,
				data: false,
			});
			toast.success('Trainer added successfully');
			goBack();
		}
	} catch (e) {
		yield put({
			type: trainersActionTypes.HANDLE_ADD_TRAINER_LOADER,
			data: false,
		});
		let message = 'Cannot add trainer';
		if (e?.response?.data?.message) {
			message = `${message}: ${e.response.data.message}`;
		} else if (e?.response?.data?.error) {
			message = `${message}: ${e.response.data.error}`;
		}
		toast.error(message);
		console.error(e);
	}
}

export function* updateTrainerSaga({ trainerId, payload, goBack }) {
	try {
		yield put({
			type: trainersActionTypes.SET_IS_TRAINER_UPDATING,
			data: true,
		});
		const response = yield API.patch(`trainers/update/${trainerId}`, jsonToFormData(payload), {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
		if (response.status === 201) {
			yield put({
				type: trainersActionTypes.SET_IS_TRAINER_UPDATING,
				data: false,
			});
			toast.success('Trainer updated successfully');
			goBack();
		}
	} catch (e) {
		yield put({
			type: trainersActionTypes.SET_IS_TRAINER_UPDATING,
			data: false,
		});
		toast.error(e.response?.data?.message || 'Error in updating trainer');
	}
}

export function* deleteTrainerSaga({ trainerId }) {
	try {
		yield put({
			type: trainersActionTypes.SET_IS_TRAINER_DELETING,
			data: true,
		});
		const response = yield API.delete(`trainers/remove/${trainerId}`);
		if (response.status === 200) {
			yield put({
				type: trainersActionTypes.SET_IS_TRAINER_DELETING,
				data: false,
			});
			toast.success('Trainer deleted successfully');
			yield getAllTrainersSaga();
		}
	} catch (e) {
		yield put({
			type: trainersActionTypes.SET_IS_TRAINER_DELETING,
			data: false,
		});
		toast.error(e.response?.data?.message || 'Error in deleting trainer');
		console.error(e);
	}
}

function* watchTrainersSaga() {
	yield takeLatest(trainersActionTypes.GET_TRAINERS, getAllTrainersSaga);
	yield takeLatest(trainersActionTypes.GET_TRAINER_BY_TRAINER_ID, getTrainerByTrainerIdSaga);
	yield takeLatest(trainersActionTypes.ADD_TRAINER, addTrainerSaga);
	yield takeLatest(trainersActionTypes.UPDATE_TRAINER, updateTrainerSaga);
	yield takeLatest(trainersActionTypes.DELETE_TRAINER, deleteTrainerSaga);
}

const trainersSagas = [watchTrainersSaga];

export default trainersSagas;
