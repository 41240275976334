export const notificationsActionTypes = {
	GET_NOTIFICATION_BY_STUDENT_ID: 'GET_NOTIFICATION_BY_STUDENT_ID',
	GET_NOTIFICATIONS_BY_STUDENT_ID_SUCCESS: 'GET_NOTIFICATIONS_BY_STUDENT_ID_SUCCESS',
	GET_NOTIFICATIONS_BY_STUDENT_ID_FAIL: 'GET_NOTIFICATIONS_BY_STUDENT_ID_FAIL',
	GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
	GET_NOTIFICATIONS_SUCCESS: 'GET_NOTIFICATIONS_SUCCESS',
	GET_NOTIFICATIONS_FAIL: 'GET_NOTIFICATIONS_FAIL',
	GET_NOTIFICATION_BY_NOTIFICATION_ID: 'GET_NOTIFICATION_BY_NOTIFICATION_ID',
	GET_NOTIFICATION_BY_NOTIFICATION_ID_SUCCESS: 'GET_NOTIFICATION_BY_NOTIFICATION_ID_SUCCESS',
	GET_NOTIFICATION_BY_NOTIFICATION_ID_FAIL: 'GET_NOTIFICATION_BY_NOTIFICATION_ID_FAIL',
	UPDATE_NOTIFICATION: 'UPDATE_NOTIFICATION',
	ADD_NOTIFICATION: 'ADD_NOTIFICATION',
	SET_IS_NOTIFICATION_UPDATING: 'SET_IS_NOTIFICATION_UPDATING',
	SET_IS_NOTIFICATION_ADDING: 'SET_IS_NOTIFICATION_ADDING',
	DELETE_NOTIFICATION: 'DELETE_NOTIFICATION',
	SET_IS_NOTIFICATION_DELETING: 'SET_IS_NOTIFICATION_DELETING',
	SET_ONE_NOTIFICATION: 'SET_ONE_NOTIFICATION',
};

export function getNotificationByStudentId(studentId) {
	return { type: notificationsActionTypes.GET_NOTIFICATION_BY_STUDENT_ID, studentId };
}

export function getAllNotifications(payload) {
	return { type: notificationsActionTypes.GET_NOTIFICATIONS, payload };
}

export function getNotificationByNotificationId(notificationId) {
	return { type: notificationsActionTypes.GET_NOTIFICATION_BY_NOTIFICATION_ID, notificationId };
}

export function updateNotification(notificationId, payload, goBack) {
	return {
		type: notificationsActionTypes.UPDATE_NOTIFICATION,
		notificationId,
		payload,
		goBack,
	};
}

export function setUpdateNotificationLoader(data) {
	return {
		type: notificationsActionTypes.SET_IS_NOTIFICATION_UPDATING,
		data,
	};
}

export function addNotification(data) {
	const { payload, history } = data;

	return {
		type: notificationsActionTypes.ADD_NOTIFICATION,
		payload,
		history,
	};
}

export function setAddNotificationLoader(data) {
	return {
		type: notificationsActionTypes.SET_IS_NOTIFICATION_ADDING,
		data,
	};
}

export function deleteNotification(notificationId) {
	return {
		type: notificationsActionTypes.DELETE_NOTIFICATION,
		notificationId,
	};
}

export function setIsDeletingNotification(data) {
	return {
		type: notificationsActionTypes.SET_IS_NOTIFICATION_DELETING,
		data,
	};
}

export function setOneNotification(data) {
	return {
		type: notificationsActionTypes.SET_ONE_NOTIFICATION,
		data,
	};
}
