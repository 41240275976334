import { trainingMaterialsActionTypes } from './TrainingMaterialsActions';
const initialState = {
	oneTrainingMaterial: null,
	trainingMaterials: null,
	uploadedFiles: null,
	isUploading: false,
	isTrainingMaterialDocumentDeleted: false,
	isError: false,
	isErrorInTrainingMaterialDelete: false,
	isDeletingTrainingMaterial: false,
	isTrainingMaterialDeleted: false,
	isTrainingMaterialsNotFound: false,
};

export default function trainingMaterialsReducer(state = initialState, action) {
	switch (action.type) {
		case trainingMaterialsActionTypes.GET_ONE_TRAINING_MATERIAL_SUCCESS:
			return {
				...state,
				oneTrainingMaterial: action.data,
			};
		case trainingMaterialsActionTypes.GET_ONE_TRAINING_MATERIAL_FAIL:
			return {
				...state,
				oneTrainingMaterial: null,
			};
		case trainingMaterialsActionTypes.GET_TRAINING_MATERIALS_BY_BATCH_ID_SUCCESS:
			return {
				...state,
				trainingMaterials: action.data,
			};
		case trainingMaterialsActionTypes.GET_TRAINING_MATERIALS_BY_BATCH_ID_FAIL:
			return {
				...state,
				trainingMaterials: null,
				isTrainingMaterialsNotFound: true,
			};
		case trainingMaterialsActionTypes.SET_IS_UPLOADING:
			return {
				...state,
				isUploading: action.data,
			};
		case trainingMaterialsActionTypes.SET_IS_TRAINING_MATERIAL_DELETING:
			return {
				...state,
				isDeletingTrainingMaterial: action.data,
			};
		case trainingMaterialsActionTypes.SET_IS_TRAINING_MATERIAL_DELETED:
			return {
				...state,
				isTrainingMaterialDeleted: action.data,
			};
		case trainingMaterialsActionTypes.SET_IS_TRAINING_MATERIAL_DOCUMENT_DELETED:
			return {
				...state,
				isTrainingMaterialDocumentDeleted: action.data,
			};
		case trainingMaterialsActionTypes.SET_UPLOADED_FILES:
			return {
				...state,
				uploadedFiles: action.data,
			};
		case trainingMaterialsActionTypes.SET_IS_ERROR:
			return {
				...state,
				isError: action.data,
			};
		case trainingMaterialsActionTypes.SET_IS_ERROR_IN_TRAINING_MATERIAL_DELETE:
			return {
				...state,
				isErrorInTrainingMaterialDelete: action.data,
			};
		case trainingMaterialsActionTypes.SET_TRAINING_MATERIALS:
			return {
				...state,
				trainingMaterials: action.data,
			};
		case trainingMaterialsActionTypes.SET_TRAINING_MATERIALS_NOT_FOUND:
			return {
				...state,
				isTrainingMaterialsNotFound: action.data,
			};
		default:
			return state;
	}
}
