export const studentsActionTypes = {
	GET_STUDENTS: 'GET_STUDENTS',
	GET_STUDENTS_SUCCESS: 'GET_STUDENTS_SUCCESS',
	GET_STUDENTS_FAIL: 'GET_STUDENTS_FAIL',
	GET_STUDENT_BY_USER_ID: 'GET_STUDENT_BY_USER_ID',
	GET_STUDENT_BY_USER_ID_SUCCESS: 'GET_STUDENT_BY_USER_ID_SUCCESS',
	GET_STUDENT_BY_USER_ID_FAIL: 'GET_STUDENT_BY_USER_ID_FAIL',
	ADD_STUDENT: 'ADD_STUDENT',
	UPDATE_STUDENT: 'UPDATE_STUDENT',
	SET_IS_STUDENT_UPDATING: 'SET_IS_STUDENT_UPDATING',
	DELETE_STUDENT: 'DELETE_STUDENT',
	SET_IS_STUDENT_DELETING: 'SET_IS_STUDENT_DELETING',
	HANDLE_ADD_STUDENT_LOADER: 'HANDLE_ADD_STUDENT_LOADER',
	SET_ONE_STUDENT: 'SET_ONE_STUDENT',
	EXPORT_STUDENT: 'EXPORT_STUDENT',
	EXPORT_STUDENT_SUCCESS: 'EXPORT_STUDENT_SUCCESS',
	EXPORT_STUDENT_FAIL: 'EXPORT_STUDENT_FAIL',
	CHANGE_USER_STATUS: 'CHANGE_USER_STATUS',
	CHANGE_USER_STATUS_LOADER: 'CHANGE_USER_STATUS_LOADER',
};

export function getAllStudents(payload) {
	return { type: studentsActionTypes.GET_STUDENTS, payload };
}

export function getStudentByUserId(userId) {
	return { type: studentsActionTypes.GET_STUDENT_BY_USER_ID, userId };
}

export function addStudent(data) {
	const { payload, history } = data;

	return {
		type: studentsActionTypes.ADD_STUDENT,
		payload,
		history,
	};
}

export function updateStudent(studentId, payload, goBack) {
	return {
		type: studentsActionTypes.UPDATE_STUDENT,
		studentId,
		payload,
		goBack,
	};
}

export function setIsUpdatingStudent(data) {
	return {
		type: studentsActionTypes.SET_IS_STUDENT_UPDATING,
		data,
	};
}

export function deleteStudent(studentId) {
	return {
		type: studentsActionTypes.DELETE_STUDENT,
		studentId,
	};
}

export function setIsDeletingStudent(data) {
	return {
		type: studentsActionTypes.SET_IS_STUDENT_DELETING,
		data,
	};
}

export function handleAddStudentLoader(payload) {
	return {
		type: studentsActionTypes.HANDLE_ADD_STUDENT_LOADER,
		payload,
	};
}

export function setOneStudent(data) {
	return {
		type: studentsActionTypes.SET_ONE_STUDENT,
		data,
	};
}

export function exportStudent(payload, fileName) {
	return { type: studentsActionTypes.EXPORT_STUDENT, payload, fileName };
}

export function changeUserStatus(userId) {
	return {
		type: studentsActionTypes.CHANGE_USER_STATUS,
		userId,
	};
}

export function changeUserStatusLoader(data) {
	return {
		type: studentsActionTypes.CHANGE_USER_STATUS_LOADER,
		data,
	};
}
