// ** Reducers Imports
import navbar from './navbar';
import layout from './layout';
import auth from './authentication';
import todo from '@src/views/apps/todo/store';
import chat from '@src/views/apps/chat/store';
import users from '@src/views/apps/user/store';
import email from '@src/views/apps/email/store';
import invoice from '@src/views/apps/invoice/store';
import calendar from '@src/views/apps/calendar/store';
import ecommerce from '@src/views/apps/ecommerce/store';
import dataTables from '@src/views/tables/data-tables/store';
import permissions from '@src/views/apps/roles-permissions/store';
import loginReducer from '../views/pages/authentication/redux/reducer';
import studentsReducer from '../custom-views/students/redux/StudentsReducer';
import coursesReducer from '../custom-views/courses/redux/CoursesReducer';
import trainingMaterialsReducer from '../custom-views/TrainingMaterials/redux/TrainingMaterialsReducer';
import learningMaterialsReducer from '../custom-views/learningMaterial/redux/LearningMaterialsReducer';
import batchesReducer from '../custom-views/batches/redux/BatchesReducer';
import trainersReducer from '../custom-views/trainers/redux/TrainersReducer';
import notificationsReducer from '../custom-views/notifications/redux/NotificationsReducer';
import feedbackReducer from '../custom-views/feedback/redux/FeedbackReducer';
import certificateReducer from '../custom-views/certifications/redux/CertificateReducer';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
	auth,
	todo,
	chat,
	email,
	users,
	navbar,
	layout,
	invoice,
	calendar,
	ecommerce,
	dataTables,
	permissions,
	loginReducer,
	studentsReducer,
	coursesReducer,
	trainingMaterialsReducer,
	learningMaterialsReducer,
	batchesReducer,
	trainersReducer,
	notificationsReducer,
	feedbackReducer,
	certificateReducer,
});

export default rootReducer;
