export const learningMaterialsActionTypes = {
	ADD_LEARNING_MATERIAL: 'ADD_LEARNING_MATERIAL',
	DELETE_LEARNING_MATERIAL: 'DELETE_LEARNING_MATERIAL',
	SET_IS_LEARNING_MATERIAL_DELETING: 'SET_IS_LEARNING_MATERIAL_DELETING',
	GET_LEARNING_MATERIAL_BY_COURSE_ID: 'GET_LEARNING_MATERIAL_BY_COURSE_ID',
	GET_LEARNING_MATERIAL_BY_COURSE_ID_SUCCESS: 'GET_LEARNING_MATERIAL_BY_COURSE_ID_SUCCESS',
	GET_LEARNING_MATERIAL_BY_COURSE_ID_FAIL: 'GET_LEARNING_MATERIAL_BY_COURSE_ID_FAIL',
	UPDATE_LEARNING_MATERIAL: 'UPDATE_LEARNING_MATERIAL',
	SET_IS_LEARNING_MATERIAL_UPDATING: 'SET_IS_LEARNING_MATERIAL_UPDATING',
	SET_ONE_LEARNING_MATERIAL: 'SET_ONE_LEARNING_MATERIAL',
};

export function addLearningMaterial(data) {
	const { payload, history } = data;

	return {
		type: learningMaterialsActionTypes.ADD_LEARNING_MATERIAL,
		payload,
		history,
	};
}

export function deleteLearningMaterial(courseId) {
	return {
		type: learningMaterialsActionTypes.DELETE_LEARNING_MATERIAL,
		courseId,
	};
}

export function deleteLearningMaterialLoader(data) {
	return {
		type: learningMaterialsActionTypes.SET_IS_LEARNING_MATERIAL_DELETING,
		data,
	};
}

export function getLearningMaterialByCourseId(courseId) {
	return { type: learningMaterialsActionTypes.GET_LEARNING_MATERIAL_BY_COURSE_ID, courseId };
}

export function updateLearningMaterial(courseId, payload, goBack) {
	return {
		type: learningMaterialsActionTypes.UPDATE_LEARNING_MATERIAL,
		courseId,
		payload,
		goBack,
	};
}

export function setIsUpdatingLearningMaterial(data) {
	return {
		type: learningMaterialsActionTypes.SET_IS_LEARNING_MATERIAL_UPDATING,
		data,
	};
}

export function setOneLearningMaterial(data) {
	return {
		type: learningMaterialsActionTypes.SET_ONE_LEARNING_MATERIAL,
		data,
	};
}
