import { studentsActionTypes } from './StudentsActions';

const initialState = {
	studentsList: null,
	oneStudent: null,
	isAddUserLoader: false,
	isStudentUpdating: false,
	isStudentDeleting: false,
	isChangeUserStatusLoader: false,
};

export default function studentsReducer(state = initialState, action) {
	switch (action.type) {
		case studentsActionTypes.GET_STUDENTS_SUCCESS:
			return {
				...state,
				studentsList: action.data,
			};
		case studentsActionTypes.GET_STUDENTS_FAIL:
			return {
				...state,
				studentsList: null,
			};
		case studentsActionTypes.GET_STUDENT_BY_USER_ID_SUCCESS:
			return {
				...state,
				oneStudent: action.data,
			};
		case studentsActionTypes.GET_STUDENT_BY_USER_ID_FAIL:
			return {
				...state,
				oneStudent: null,
			};
		case studentsActionTypes.HANDLE_ADD_STUDENT_LOADER:
			return {
				...state,
				isAddUserLoader: action.payload,
			};
		case studentsActionTypes.SET_ONE_STUDENT:
			return {
				...state,
				oneStudent: action.data,
			};
		case studentsActionTypes.SET_IS_STUDENT_UPDATING:
			return {
				...state,
				isStudentUpdating: action.data,
			};
		case studentsActionTypes.SET_IS_STUDENT_DELETING:
			return {
				...state,
				isStudentDeleting: action.data,
			};
		case studentsActionTypes.CHANGE_USER_STATUS_LOADER:
			return {
				...state,
				isChangeUserStatusLoader: action.data,
			};

		default:
			return state;
	}
}
