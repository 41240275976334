import { put, takeLatest } from '@redux-saga/core/effects';
import { API } from '../../../utility/axios';
import { toast } from 'react-toastify';
import { getAllNotifications, notificationsActionTypes, setAddNotificationLoader } from './NotificationsActions';

export function* getNotificationsByStudentIdSaga({ studentId }) {
	try {
		const { data } = yield API.get(`notification/all/student/${studentId}`);
		yield put({
			type: notificationsActionTypes.GET_NOTIFICATIONS_BY_STUDENT_ID_SUCCESS,
			data: data,
		});
	} catch (e) {
		toast.error(e.response?.data?.message || 'Error in retrieving notifications data');
		yield put({ type: notificationsActionTypes.GET_NOTIFICATIONS_BY_STUDENT_ID_FAIL });
	}
}

export function* getAllNotificationsSaga({ payload }) {
	try {
		const { data } = yield API.get(`notification/all`, payload);
		yield put({
			type: notificationsActionTypes.GET_NOTIFICATIONS_SUCCESS,
			data: data,
		});
	} catch (e) {
		toast.error(e.response?.data?.message || 'Error in retrieving notifications data');
		yield put({ type: notificationsActionTypes.GET_NOTIFICATIONS_FAIL });
	}
}

export function* getNotificationsByNotificationIdSaga({ notificationId }) {
	try {
		const { data } = yield API.get(`notification/findOne/${notificationId}`);
		yield put({
			type: notificationsActionTypes.GET_NOTIFICATION_BY_NOTIFICATION_ID_SUCCESS,
			data: data,
		});
	} catch (e) {
		toast.error(e.response?.data?.message || 'Error in retrieving notifications data');
		yield put({ type: notificationsActionTypes.GET_NOTIFICATION_BY_NOTIFICATION_ID_FAIL });
	}
}

export function* updateNotificationSaga({ notificationId, payload, goBack }) {
	try {
		yield put({
			type: notificationsActionTypes.SET_IS_NOTIFICATION_UPDATING,
			data: true,
		});
		const response = yield API.patch(`notification/update/${notificationId}`, payload);
		if (response.status === 201) {
			yield put({
				type: notificationsActionTypes.SET_IS_NOTIFICATION_UPDATING,
				data: false,
			});
			toast.success('Notification updated successfully');
			goBack();
		}
	} catch (e) {
		yield put({
			type: notificationsActionTypes.SET_IS_NOTIFICATION_UPDATING,
			data: false,
		});
		toast.error(e.response?.data?.message || 'Error in updating notification');
	}
}
export function* addNotificationSaga({ payload, history }) {
	try {
		yield put(setAddNotificationLoader(true));
		const response = yield API.post(`Notification/create`, payload);

		if (response.status === 201) {
			toast.success('Notification added successfully');
			history();
		}
		yield put(setAddNotificationLoader(false));
	} catch (e) {
		yield put(setAddNotificationLoader(false));
		let message = 'Cannot add notification';
		if (e?.response?.data?.message) {
			message = `${message}: ${e.response.data.message}`;
		} else if (e?.response?.data?.error) {
			message = `${message}: ${e.response.data.error}`;
		}
		toast.error(message);
		console.error(e);
	}
}

export function* deleteNotificationSaga({ notificationId }) {
	try {
		yield put({
			type: notificationsActionTypes.SET_IS_NOTIFICATION_DELETING,
			data: true,
		});
		const response = yield API.delete(`notification/remove/${notificationId}`);
		if (response.status === 200) {
			yield put(getAllNotifications());
			yield put({
				type: notificationsActionTypes.SET_IS_NOTIFICATION_DELETING,
				data: false,
			});
			toast.success('Notification deleted successfully');
		}
	} catch (e) {
		yield put({
			type: notificationsActionTypes.SET_IS_NOTIFICATION_DELETING,
			data: false,
		});
		toast.error(e.response?.data?.message || 'Error in deleting notification');
	}
}

function* watchNotificationsSaga() {
	yield takeLatest(notificationsActionTypes.GET_NOTIFICATION_BY_STUDENT_ID, getNotificationsByStudentIdSaga);
	yield takeLatest(notificationsActionTypes.GET_NOTIFICATIONS, getAllNotificationsSaga);
	yield takeLatest(
		notificationsActionTypes.GET_NOTIFICATION_BY_NOTIFICATION_ID,
		getNotificationsByNotificationIdSaga,
	);
	yield takeLatest(notificationsActionTypes.UPDATE_NOTIFICATION, updateNotificationSaga);
	yield takeLatest(notificationsActionTypes.ADD_NOTIFICATION, addNotificationSaga);
	yield takeLatest(notificationsActionTypes.DELETE_NOTIFICATION, deleteNotificationSaga);
}

const notificationsSagas = [watchNotificationsSaga];

export default notificationsSagas;
