export const coursesActionTypes = {
	GET_COURSES: 'GET_COURSES',
	GET_COURSES_SUCCESS: 'GET_COURSES_SUCCESS',
	GET_COURSES_FAIL: 'GET_COURSES_FAIL',
};

export function getAllCourses(payload) {
	return { type: coursesActionTypes.GET_COURSES, payload };
}
//https://dev-api.eddwise.com/api/v1/auth/login
//{
//"email":"umeshgathsara@gmail.com",
//"password":"Gathsara@123"
//}
