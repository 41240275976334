import { put, takeLatest } from '@redux-saga/core/effects';
import { toast } from 'react-toastify';
import { API } from '../../../utility/axios';
import { certificateActionTypes, setAddCertificateLoader } from './CertificateActions';
import { jsonToFormData } from '../../../utility/customUtils';

export function* getAllCertificatesSaga({ payload }) {
	try {
		const { data } = yield API.get(`certificate/all`, payload);
		yield put({
			type: certificateActionTypes.GET_CERTIFICATES_SUCCESS,
			data: data,
		});
	} catch (e) {
		toast.error(e.response?.data?.message || 'Error in retrieving certificate data');
		yield put({ type: certificateActionTypes.GET_CERTIFICATES_FAIL });
	}
}

export function* addCertificateSaga({ payload, history }) {
	try {
		yield put(setAddCertificateLoader(true));
		const response = yield API.post(`certificate/create`, jsonToFormData(payload), {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});

		if (response.status === 201) {
			toast.success('Certificate added successfully');
			history();
		}
		yield put(setAddCertificateLoader(false));
	} catch (e) {
		put(setAddCertificateLoader(false));
		let message = 'Cannot add Certificate';
		if (e?.response?.data?.message) {
			message = `${message}: ${e.response.data.message}`;
		} else if (e?.response?.data?.error) {
			message = `${message}: ${e.response.data.error}`;
		}
		toast.error(message);
		console.error(e);
	}
}

export function* getCertificateByCertificateIdSaga({ certificateId }) {
	try {
		const { data } = yield API.get(`certificate/findOne/${certificateId}`);
		yield put({
			type: certificateActionTypes.GET_CERTIFICATE_BY_CERTIFICATE_ID_SUCCESS,
			data: data,
		});
	} catch (e) {
		toast.error(e.response?.data?.message || 'Error in retrieving certificate data');
		yield put({ type: certificateActionTypes.GET_CERTIFICATE_BY_CERTIFICATE_ID_FAIL });
	}
}

export function* getCertificateByStudentIdSaga({ studentId }) {
	try {
		const { data } = yield API.get(`certificate/student/${studentId}`);
		yield put({
			type: certificateActionTypes.GET_CERTIFICATES_BY_STUDENT_ID_SUCCESS,
			data: data,
		});
	} catch (e) {
		toast.error(e.response?.data?.message || 'Error in retrieving certificate data');
		yield put({ type: certificateActionTypes.GET_CERTIFICATES_BY_STUDENT_ID_FAIL });
	}
}

function* watchCertificateSaga() {
	yield takeLatest(certificateActionTypes.GET_CERTIFICATES, getAllCertificatesSaga);
	yield takeLatest(certificateActionTypes.ADD_CERTIFICATE, addCertificateSaga);
	yield takeLatest(certificateActionTypes.GET_CERTIFICATE_BY_CERTIFICATE_ID, getCertificateByCertificateIdSaga);
	yield takeLatest(certificateActionTypes.GET_CERTIFICATE_BY_STUDENT_ID, getCertificateByStudentIdSaga);
}

const certificateSagas = [watchCertificateSaga];

export default certificateSagas;
