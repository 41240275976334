import { put, takeLatest } from '@redux-saga/core/effects';
import { toast } from 'react-toastify';
import { API } from '../../../utility/axios';
import { learningMaterialsActionTypes } from './LearningMaterialsActions';
import { jsonToFormData } from '../../../utility/customUtils';
import { getAllCourses } from '../../courses/redux/CoursesActions';

export function* getLearningMaterialByCourseIdSaga({ courseId }) {
	try {
		const { data } = yield API.get(`course/find/${courseId}`);
		yield put({
			type: learningMaterialsActionTypes.GET_LEARNING_MATERIAL_BY_COURSE_ID_SUCCESS,
			data: data,
		});
	} catch (e) {
		toast.error(e.response?.data?.message || 'Error in retrieving training programs data');
		yield put({ type: learningMaterialsActionTypes.GET_LEARNING_MATERIAL_BY_COURSE_ID_FAIL });
	}
}

export function* addLearningMaterialsSaga({ payload, history }) {
	try {
		const response = yield API.post(`course/create`, jsonToFormData(payload), {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});

		if (response.status === 201) {
			yield put(getAllCourses());
			toast.success('Training program added successfully');
			history();
		}
	} catch (e) {
		let message = 'Cannot add training program';
		if (e?.response?.data?.message) {
			message = `${message}: ${e.response.data.message}`;
		} else if (e?.response?.data?.error) {
			message = `${message}: ${e.response.data.error}`;
		}
		toast.error(message);
		console.error(e);
	}
}

export function* deleteLearningMaterialSaga({ courseId }) {
	try {
		yield put({
			type: learningMaterialsActionTypes.SET_IS_LEARNING_MATERIAL_DELETING,
			data: true,
		});
		const response = yield API.delete(`course/remove/${courseId}`);
		if (response.status === 200) {
			yield put(getAllCourses());
			yield put({
				type: learningMaterialsActionTypes.SET_IS_LEARNING_MATERIAL_DELETING,
				data: false,
			});
			toast.success('Training program deleted successfully');
		}
	} catch (e) {
		yield put({
			type: learningMaterialsActionTypes.SET_IS_LEARNING_MATERIAL_DELETING,
			data: false,
		});
		toast.error(e.response?.data?.message || 'Error in deleting training program');
	}
}

export function* updateLearningMaterialSaga({ courseId, payload, goBack }) {
	try {
		yield put({
			type: learningMaterialsActionTypes.SET_IS_LEARNING_MATERIAL_UPDATING,
			data: true,
		});
		const response = yield API.put(`course/update/${courseId}`, jsonToFormData(payload), {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
		if (response.status === 200) {
			yield put({
				type: learningMaterialsActionTypes.SET_IS_LEARNING_MATERIAL_UPDATING,
				data: false,
			});
			toast.success('Training program updated successfully');
			goBack();
		}
	} catch (e) {
		yield put({
			type: learningMaterialsActionTypes.SET_IS_LEARNING_MATERIAL_UPDATING,
			data: false,
		});
		toast.error(e.response?.data?.message || 'Error in updating training program');
	}
}

function* watchLearningMaterialsSaga() {
	yield takeLatest(learningMaterialsActionTypes.ADD_LEARNING_MATERIAL, addLearningMaterialsSaga);
	yield takeLatest(learningMaterialsActionTypes.DELETE_LEARNING_MATERIAL, deleteLearningMaterialSaga);
	yield takeLatest(
		learningMaterialsActionTypes.GET_LEARNING_MATERIAL_BY_COURSE_ID,
		getLearningMaterialByCourseIdSaga,
	);
	yield takeLatest(learningMaterialsActionTypes.UPDATE_LEARNING_MATERIAL, updateLearningMaterialSaga);
}

const learningMaterialsSagas = [watchLearningMaterialsSaga];

export default learningMaterialsSagas;
