import { all, fork } from 'redux-saga/effects';
import loginSagas from '../views/pages/authentication/redux/saga';
import studentsSagas from '../custom-views/students/redux/studentsSaga';
import coursesSagas from '../custom-views/courses/redux/CoursesSaga';
import trainingMaterialsSagas from '../custom-views/TrainingMaterials/redux/TrainingMaterialsSaga';
import learningMaterialsSagas from '../custom-views/learningMaterial/redux/LearningMaterialsSaga';
import batchesSagas from '../custom-views/batches/redux/BatchesSaga';
import trainersSagas from '../custom-views/trainers/redux/TrainersSaga';
import notificationsSagas from '../custom-views/notifications/redux/NotificationsSaga';
import feedbackSagas from '../custom-views/feedback/redux/FeedbackSaga';
import certificateSagas from '../custom-views/certifications/redux/CertificateSaga';

export default function* rootSaga() {
	yield all(loginSagas.map((s) => fork(s)));
	yield all(studentsSagas.map((s) => fork(s)));
	yield all(coursesSagas.map((s) => fork(s)));
	yield all(trainingMaterialsSagas.map((s) => fork(s)));
	yield all(learningMaterialsSagas.map((s) => fork(s)));
	yield all(batchesSagas.map((s) => fork(s)));
	yield all(trainersSagas.map((s) => fork(s)));
	yield all(notificationsSagas.map((s) => fork(s)));
	yield all(feedbackSagas.map((s) => fork(s)));
	yield all(certificateSagas.map((s) => fork(s)));
}
